<template>
  <div class="wrapper">
    <PageHeader />
    <NuxtPage />
    <PageFooter />
  </div>
</template>
<style lang="scss">
.wrapper {
  position: relative;
  max-width: 460px;
  min-width: 320px;
  margin: auto;
  padding: 16px;
}
</style>